<template>
    <div class="comment-item">
        <img class="avatar" :src="commentItem.about && commentItem.about.userIcon" alt="" />
        <div class="comment-info">
            <div class="name">
                <span>{{ commentItem.about.userName }}</span>
                <span>{{ formatTime(commentItem.messageDate) }}</span>
            </div>
            <p class="comment">{{ commentItem.messageInfo }}</p>
            <!-- <div class="replay">
                <a-tooltip>
                    <template slot="title"> 此功能暂未开发 </template>
                    <span v-html="ARTICLE_ICON.replayIcon"></span>
                    回复
                </a-tooltip>
            </div> -->
            <!--  <div class="child-replay">
                <div class="comment-item">
                    <img class="avatar" src="../../../assets/images/1.jpg" alt="" />
                    <div class="comment-info">
                        <div class="name">
                            <span
                                >peterlits <span class="replay-bridge">回复 <i>段某</i></span></span
                            >
                            <span>12小时前</span>
                        </div>
                        <p class="comment">能点踩吗请问</p>
                        <div class="replay">
                            <span v-html="ARTICLE_ICON.replayIcon"></span>
                            回复
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</template>

<script>
    import {ARTICLE_ICON} from '@/lib/article_const'
    // import CommentItem from './CommentItem.vue';
    import {timeLongAgo} from '@/utils/dayjs'
    export default {
        name: 'CommentItem',
        components: {},
        props: {
            commentItem: {
                type: Object,
                default: () => { }
            }
        },
        data() {
            return {
                ARTICLE_ICON
            };
        },

        mounted() {

        },

        methods: {
            formatTime(time) {
                return timeLongAgo(time)
            }
        },
    };
</script>

<style lang="less" scoped>
    .comment-item {
        display: flex;
        img {
            height: 40px;
            width: 40px;
            margin-right: 20px;
            border-radius: 50%;
        }
        i {
            font-style: normal;
            color: #252933;
        }
        .replay-bridge {
            color: #8a919f;
        }
        .comment-info {
            flex: 1;
            .name {
                display: flex;
                justify-content: space-between;
                & > span:nth-of-type(1) {
                    font-size: 15px;
                    color: #252933;
                    line-height: 26px;
                }
                & > span:nth-of-type(2) {
                    font-size: 14px;
                    color: #8a919f;
                    line-height: 22px;
                }
            }
            .comment {
                font-size: 14px;
                color: #515767;
                line-height: 2rem;
                // margin: 8px 0;
            }
            /deep/ .replay {
                color: #8a919f;
                font-size: 14px;
                line-height: 22px;
                cursor: not-allowed;
                svg {
                    // color: red;
                    vertical-align: -2px;
                }
            }
            .child-replay {
                padding: 16px;
                margin-top: 16px;
                background-color: #f9fafb;
            }
        }
    }
</style>