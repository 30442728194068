<template>
    <div class="BlogLeaveMessage">
        <div class="c-title">
            <h1>a-puhaha</h1>
            <p>我有故人抱剑去，斩尽春风未曾归。</p>
        </div>
        <!-- 内容输出部分 -->
        <div class="c-wrap">
            <MessageComment />
        </div>
    </div>
</template>

<script>
    import MessageComment from './components/MessageComment.vue';
    export default {
        components: {MessageComment},
        name: 'BlogLeaveMessage',

        data() {
            return {

            };
        },

        mounted() {

        },

        methods: {

        },
    };
</script>

<style lang="less" scoped>
    .BlogLeaveMessage {
        position: relative;
        min-height: calc(100vh - 50px);
        background-color: #f1f3f4;
        .c-title {
            box-sizing: border-box;
            height: 100px;
            padding: 20px 20px 0;
            background-color: #f9f9f9;
            h1 {
                font-weight: 200;
            }
            p {
                margin-top: 5px;
                letter-spacing: 2px;
                color: #777;
                font-size: 13px;
            }
        }
        .c-wrap {
            padding: 20px;
        }
    }
</style>