<template>
    <div class="article-comment">
        <div class="post-comment-con">
            <p class="title">对a-puahaha说点什么吧</p>
            <textarea ref="textarea" v-model="commentContent" name="" id="" placeholder="说点什么吧……"></textarea>
            <div class="operator-con">
                <Poptip ref="poptip" placement="bottom" width="320">
                    <span class="emoji-span" @click="$refs.textarea.focus()"><a-icon class="smile" type="smile" />表情</span>
                    <div class="api" slot="content">
                        <ul class="emoji-list">
                            <li @click="addEmoji(item)" v-for="(item, index) in EMOJI" :key="index">{{ item }}</li>
                        </ul>
                    </div>
                </Poptip>

                <a-button @click="postComment" class="post-comment-btn" type="primary">发表留言</a-button>
            </div>
        </div>

        <!-- <p class="comment-num">{{ commentList.length }}条评论 <span v-html="ARTICLE_ICON.hotIcon"></span></p> -->
        <div class="CommentItem" v-for="item in commentList" :key="item._id">
            <CommentItem :commentItem="item" />
        </div>
        <div class="fenye">
            <a-pagination @change="changeCurrent" v-model="current" :total="total" show-less-items :pageSize="pageSize" />
        </div>
    </div>
</template>

<script>
    import CommentItem from './CommentItem.vue'
    import {ARTICLE_ICON} from '@/lib/article_const'
    import {EMOJI} from '@/lib/emoji_const'
    import {mapState, mapActions} from 'vuex'
    export default {
        components: {CommentItem},
        name: 'MessageComment',

        data() {
            return {
                ARTICLE_ICON,
                EMOJI,
                commentContent: '',
                commentList: [],
                total: 100,
                current: 1,
                pageSize: 20,
            }
        },
        computed: {
            ...mapState({
                vxLoginStatus: (state) => state.user.loginStatus,
                vxUserInfo: (state) => state.user.userInfo,
            }),
        },

        methods: {
            ...mapActions({
                vxSetLeaveMessage: 'friend/setLeaveMessage',
                vxGetLeaveMessage: 'friend/getLeaveMessage',
            }),
            addEmoji(emoji) {
                this.$refs.textarea.focus()
                let index = this.$refs.textarea.selectionEnd
                console.log(index)
                this.commentContent = this.commentContent.slice(0, index) + emoji + this.commentContent.slice(index)
                // console.dir(this.$refs.textarea);
                this.$refs.poptip.visible = false
            },
            async postComment() {
                if (!this.vxLoginStatus) {
                    return this.$notification.open({
                        message: 'User not logged in',
                        description: '请点击网页右上角小钥匙登录后，再发表留言',
                        icon: <a-icon type="smile" style="color: #108ee9" />,
                    })
                }
                if (!this.commentContent.trim()) {
                    return this.$message.error('留言内容不能为空')
                }
                try {
                    const data = await this.vxSetLeaveMessage({
                        inputMessage: this.commentContent,
                        id: this.vxUserInfo.id,
                    })
                    console.log(data)
                    this.commentContent = ''
                    this.getComments()
                    // if (data.code) {
                    //     this.$message.success(data.msg)
                    //     this.commentContent = ''
                    //     this.getComments()
                    // } else {
                    //     this.$message.error(data.msg)

                    // }
                } catch (error) {
                    this.$message.error('评论失败，请稍后再试')
                }
            },

            async getComments() {
                const data = await this.vxGetLeaveMessage({skip: this.current - 1, pageSize: this.pageSize})
                console.log(data)
                this.total = data.count
                this.commentList = data.data
            },
            changeCurrent() {
                this.getComments()
            },
        },
        mounted() {
            this.getComments()
        },
    }
</script>

<style lang="less" scoped>
    .article-comment {
        background-color: #fff;
        padding: 32px;
        border-radius: 4px;
        .post-comment-con {
            display: flex;
            flex-direction: column;
            margin-bottom: 10px;
            .title {
                margin-bottom: 15px;
                color: rgba(0, 0, 0, 0.85);
                font-size: 18px;
                font-weight: 200;
            }
            textarea {
                height: 114px;
                border: 1px solid #1e80ff;
                border-radius: 4px;
                padding: 6px 12px;
                background: url('../../../assets/images/friend.jpg') no-repeat right/contain;
                transition: all 0.5s;
                &:focus {
                    background: url('../../../assets/images/friend.jpg') no-repeat right/contain;
                    background-position-y: 200px;
                }
            }
            .emoji-span {
                /*  width: 62px;
                                                                                                                                                                                                                                height: 24px; */
                font-size: 14px;
                text-align: center;
                padding: 3px 6px;
                line-height: 24px;
                border-radius: 4px;
                color: #252933;
                background-color: #ffdf8f;
                cursor: url('http://www.a-puhaha.com/static/image/link.cur'), pointer;
                .smile {
                    margin-right: 2px;
                }
            }
            .post-comment-btn {
                width: 124px;
                background: #448bff linear-gradient(45deg, #448bff, #44e9ff);
                border-radius: 50px;
            }
            .operator-con {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin: 10px 0;
                user-select: none;
                .emoji-list {
                    display: flex;
                    flex-wrap: wrap;
                    li {
                        height: 25px;
                        width: 25px;
                        margin: 8px;
                        font-size: 25px;
                        color: #0095f6;
                        cursor: url('http://www.a-puhaha.com/static/image/link.cur'), pointer;
                    }
                }
            }
        }

        /deep/ .comment-num {
            font-size: 18px;
            color: #252933;
            margin-bottom: 25px;
            span {
                margin-left: 4px;
            }
            svg {
                vertical-align: -2px;
            }
        }
        /deep/ .fenye {
            min-width: 240px;
            max-width: 300px;
            height: 30px;
            margin: 50px auto;
            cursor: url('http://www.a-puhaha.com/static/image/link.cur'), pointer;
            ul {
                .ant-pagination-item,
                .ant-pagination-prev,
                .ant-pagination-next {
                    margin-right: 0;
                }
                .ant-pagination-item,
                .ant-pagination-item-link {
                    border: none;
                }
                .ant-pagination-item {
                    border-radius: 0;
                    font-size: 12px;
                }
                .ant-pagination-item-active {
                    color: #fff;
                    background-color: #111;
                    a {
                        color: #fff;
                    }
                }
                li:hover {
                    a {
                        color: #000 !important;
                    }
                    background-color: #eeeeee;
                }
            }
            .ant-pagination-prev,
            .ant-pagination-next,
            .ant-pagination-jump-prev,
            .ant-pagination-jump-next {
                min-width: 30px;
                height: 30px;
            }
        }
        .CommentItem {
            padding: 16px 0;
        }
    }
</style>
